<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";
import allCountries from "@/helpers/all-countries";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Pickup Points Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      inputPage: "",

      pickupPoints: null,
      pickupPoint: {
        city: "",
        street: "",
        postCode: "",
        countryCode: "PL"
      },

      showModal: false
    };
  },

  validations: {
    pickupPoint: {
      city: {required},
      street: {required},
      postCode: {required},
      countryCode: {required}
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('pickup-points.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "city", label: this.$t('pickup-points.table.city')},
        {key: "street", label: this.$t('pickup-points.table.street')},
        {key: "postCode", label: this.$t('pickup-points.table.postcode')},
        {key: "countryCode", label: this.$t('pickup-points.table.country')},
        {key: "status", label: 'Status'},
        {key: "createdAt", label: this.$t('pickup-points.table.created-at'), formatter: dateUtil.asDateTime},
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    getAllCountries() {
      return allCountries;
    },

    async loadPickupPoints() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/pickup-point/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage
        }
      });

      const data = result.data;

      this.pickupPoints = data.pickupPoints
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.pickupPoints;
    },

    createOrEditPickupPoint() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.pickupPoint);

      axios.put(`/pickup-point`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.pickupPoint.id ? "pickup-points.edit.success" : "pickup-points.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.pickupPoint.id) {
          for (const index in this.pickupPoints) {
            if (this.pickupPoints[index].id === this.pickupPoint.id) {
              this.pickupPoints[index] = this.pickupPoint;
              break;
            }
          }

          this.$refs.table.refresh();
        } else {
          this.pickupPoints.push(this.pickupPoint)
        }

        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    changeActiveStatus(pickupPoint) {
      this.submitted = true;

      const active = !pickupPoint.active;
      const json = JSON.stringify({
        "id": pickupPoint.id,
        "active": active
      });

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.post(`/pickup-point/change-active-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast(this.$t(active ? 'pickup-points.inactive.success' : 'pickup-points.active.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          for (const index in this.pickupPoints) {
            if (this.pickupPoints[index].id === pickupPoint.id) {
              this.pickupPoints[index].active = active;
              break;
            }
          }

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, "")
    },

    changeDeletedStatus(pickupPoint) {
      this.submitted = true;

      const json = JSON.stringify({
        "id": pickupPoint.id,
        "deleted": true
      });

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.post(`/pickup-point/change-deleted-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast(this.$t('pickup-points.deleted.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.pickupPoints = this.pickupPoints.filter(element => element.id !== pickupPoint.id)
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, "")
    },

    hideModal() {
      this.submitted = false;
      this.showModal = false;
      this.pickupPoint = {
        city: "",
        street: "",
        postCode: "",
        countryCode: "PL"
      };
    },

    editPickupPoint(pickupPoint) {
      this.pickupPoint = Object.assign({}, pickupPoint);
      this.showModal = true
    }

  },

  async created() {
    try {
      await this.loadPickupPoints()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('pickup-points.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success mb-2" @click="showModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('pickup-points.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="pickupPoints">
              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{
                          $t('table.entries')
                        }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="table.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                            @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-right">
                      <label class="d-inline-flex align-items-center mx-2">
                        <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2"/>
                      </label>
                      <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">Idź do
                        strony
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="row py-3">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                      :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table

                      ref="table"
                      :items="loadPickupPoints"
                      :fields="getFields()"
                      responsive="sm"
                      :per-page="table.perPage"
                      :current-page="table.currentPage"
                      :sort-by.sync="table.sortBy"
                      :sort-desc.sync="table.sortDesc"


                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(status)="{ item }">
                      <span :class="item.active ? 'badge badge-soft-danger' : 'badge badge-soft-success'"
                            class="font-size-11"> {{ $t(item.active ? 'table.inactive' : 'table.active') }}</span>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <a @click="changeActiveStatus(item)" class="clickable-element mr-3 text-primary"><i
                          class="font-size-18" :class="item.active ? 'fa fa-toggle-off' : 'fa fa-toggle-on'"></i></a>
                      <a @click="changeDeletedStatus(item)" class="clickable-element mr-3 text-danger"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                      <a @click="editPickupPoint(item)" class="clickable-element mr-3 text-primary"><i
                          class="mdi mdi-pencil font-size-18"></i></a>
                    </template>
                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{
                          $t('table.entries-footer', {
                            'amount': paginationHelper.getElementsAmount(table),
                            'elements': paginationHelper.getElementsCount(table),
                            'all': table.totalRows
                          })
                        }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                      :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t(this.pickupPoint.id ? 'pickup-points.edit.title' : 'pickup-points.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="city">{{ $t('pickup-points.table.city') }}</label>
          <input id="city" v-model="pickupPoint.city" :class="{ 'is-invalid': submitted && $v.pickupPoint.city.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.pickupPoint.city.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label for="street">{{ $t('pickup-points.table.street') }}</label>
          <input id="street" v-model="pickupPoint.street"
                 :class="{ 'is-invalid': submitted && $v.pickupPoint.street.$error }" class="form-control" type="text"/>
          <div v-if="submitted && !$v.pickupPoint.street.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label for="street">{{ $t('pickup-points.table.postcode') }}</label>
          <input id="street" v-model="pickupPoint.postCode"
                 :class="{ 'is-invalid': submitted && $v.pickupPoint.postCode.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.pickupPoint.postCode.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label for="country-code">{{ $t('pickup-points.table.country') }}</label>
          <select v-model="pickupPoint.countryCode" class="custom-select"
                  :class="{ 'is-invalid': submitted && $v.pickupPoint.countryCode.$error }">
            <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2.toUpperCase()}`">
              {{ item.name }} - {{ item.iso2.toUpperCase() }}
            </option>
          </select>
          <div v-if="submitted && !$v.pickupPoint.countryCode.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditPickupPoint" variant="success">
            {{ $t(this.pickupPoint.id ? 'pickup-points.edit.button' : 'pickup-points.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>